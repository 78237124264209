<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Expanded cards</h3>

	<p><img alt="Expand button on card image" srcset="/docimages/expanded_card-1.png 5x" class="k-help-img float-right"> When viewing the “card” for a <span v-html="link('item_card', 'item')"></span> or the <span v-html="link('document_card', 'document')"></span>, click the <v-icon small>fas fa-expand</v-icon> icon to expand the card:</p>
	<img alt="Expanded card image" srcset="/docimages/expanded_card-2.png 3x" class="k-help-img block">
	<ul>
		<li>When viewing on a full-size screen, the expanded card shows the same information as the normal (unexpanded) card; but it can be especially useful to view the expanded card if the item has a long note or supplemental information field.</li>
		<li>On a small screen (e.g. a smartphone), the expanded card is the only way to view information other than the full statement of an item or title of a document.</li>
		<li>Also, when an item that is associated with another item, you can click the association in the first item’s expanded card to jump to the expanded card of the associated item.</li>
		<li>Use the arrow buttons at the top of the expanded card to navigate back and forth between other expanded cards you’ve viewed.</li>
		<li>Click the <v-icon small>fas fa-collapse</v-icon> icon in the upper-right corner to re-collapse the card.</li>
	</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	